import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from "react";
import { getAppearanceLS } from "../helpers/localStorage";
import { UserRoleEnum } from "../types/User";
import reducer from "./reducer";
import { AppStateContext, IAppContext } from "./types";

const appearanceLs = getAppearanceLS();

const initialState: AppStateContext = {
  appearance: appearanceLs || undefined,
  isAuth: false,
  user: {
    _id: "",
    email: "",
    fullName: "",
    phoneNumber: "",
    role: UserRoleEnum.ADMIN,
    updatedAt: "",
  },
  selectedDeviceIdInStore: undefined,
};

export const AppContext = createContext<IAppContext>({
  state: initialState,
  dispatch: () => null,
});

export const useAppContext = (): IAppContext => useContext(AppContext);

interface Props {
  children: ReactNode;
}

export const AppStoreProvider = ({ children }: Props): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
