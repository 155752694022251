import { PaletteMode } from "@mui/material";

const LocalStorageEnum = {
  APPEARANCE: "sentinel_app_appearance",
};

const getAppearanceLS = (): PaletteMode | null =>
  localStorage.getItem(LocalStorageEnum.APPEARANCE) as PaletteMode;

const setAppearanceLS = (appearance: PaletteMode): void =>
  localStorage.setItem(LocalStorageEnum.APPEARANCE, appearance);

export { getAppearanceLS, setAppearanceLS };
