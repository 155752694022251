import { SvgIconProps, SvgIcon } from "@mui/material";
import React from "react";

const StuffPageIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon
    className="svg-icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M512 512m-414 0a414 414 0 1 0 828 0 414 414 0 1 0-828 0Z"
      fill="#F0C48A"
    />
    <path
      d="M731.2 362.6H292.8c-20.2 0-36.6-16.4-36.6-36.6 0-20.2 16.4-36.6 36.6-36.6h438.6c20.2 0 36.6 16.4 36.6 36.6-0.2 20.2-16.6 36.6-36.8 36.6z"
      fill="#FD919E"
    />
    <path
      d="M767.8 328.2H256.2c1.2 19.2 17 34.4 36.4 34.4h438.6c19.6 0 35.4-15.2 36.6-34.4z"
      fill="#E8677D"
    />
    <path
      d="M512 11.8c-102.4 0-185.6 83-185.6 185.6v92.2h371.2V197.4c0-102.6-83.2-185.6-185.6-185.6z"
      fill="#FD919E"
    />
    <path
      d="M697.6 197.4c0-102.4-83-185.6-185.6-185.6-4.2 0-8.4 0.2-12.6 0.4 83.6 18 146.2 92.4 146.2 181.4v92.2H326.4v3.8h371.2V197.4z"
      fill="#E8677D"
    />
    <path
      d="M512 11.8c-14.6 0-28.6 1.6-42.2 4.8v273h84.4V16.6c-13.6-3.2-27.6-4.8-42.2-4.8z"
      fill="#FD919E"
    />
    <path
      d="M863.4 1012.2H160.6v-222c0-42.2 34.2-76.4 76.4-76.4h550c42.2 0 76.4 34.2 76.4 76.4v222z"
      fill="#A4CFF2"
    />
    <path
      d="M812.6 718.2v294.2h50.8V790.2c0-33.4-21.2-61.6-50.8-72zM328.8 1012.2h-33.8v-121.8c18.6 0 33.8 15.2 33.8 33.8v88z"
      fill="#7BABF1"
    />
    <path
      d="M863.4 1024H160.6c-6.4 0-11.8-5.2-11.8-11.8v-222c0-48.6 39.6-88.2 88.2-88.2h550c48.6 0 88.2 39.6 88.2 88.2v222.2c0 6.4-5.2 11.6-11.8 11.6zM172.2 1000.6h679.4V790.2c0-35.6-29-64.8-64.8-64.8h-550c-35.6 0-64.8 29-64.8 64.8v210.4z"
      fill="#4C4372"
    />
    <path
      d="M295 1024c-6.4 0-11.8-5.2-11.8-11.8v-121.8c0-6.4 5.2-11.8 11.8-11.8 6.4 0 11.8 5.2 11.8 11.8v121.8c0 6.6-5.4 11.8-11.8 11.8zM717.2 1024c-6.4 0-11.8-5.2-11.8-11.8v-121.8c0-6.4 5.2-11.8 11.8-11.8s11.8 5.2 11.8 11.8v121.8c0 6.6-5.2 11.8-11.8 11.8z"
      fill="#4C4372"
    />
    <path
      d="M512 854.8c-49.2 0-89.2-39.8-89.2-89.2v-190.6h178.2v190.6c0.2 49.4-39.8 89.2-89 89.2z"
      fill="#D3E6F8"
    />
    <path
      d="M550.4 846.2c30-14.4 50.8-45 50.8-80.4v-190.6h-50.8v271z"
      fill="#A4CFF2"
    />
    <path
      d="M512 866.6c-55.6 0-100.8-45.2-100.8-100.8v-190.6c0-6.4 5.2-11.8 11.8-11.8h178.2c6.4 0 11.8 5.2 11.8 11.8v190.6c-0.2 55.6-45.4 100.8-101 100.8z m-77.4-279.8v179c0 42.6 34.8 77.4 77.4 77.4s77.4-34.8 77.4-77.4v-179h-154.8z"
      fill="#4C4372"
    />
    <path
      d="M512 663.4c-90.6 0-164.2-73.4-164.2-164.2v-136.6h328.4v136.6c0 90.6-73.6 164.2-164.2 164.2z"
      fill="#D3E6F8"
    />
    <path
      d="M623.4 498.2c0 82.8-61.2 151.2-140.8 162.4 9.6 1.8 19.4 2.6 29.4 2.6 90.6 0 164.2-73.4 164.2-164.2v-136.6h-52.8v135.8z"
      fill="#A4CFF2"
    />
    <path
      d="M512 675.2c-97 0-175.8-78.8-175.8-175.8v-136.6c0-6.4 5.2-11.8 11.8-11.8h328.4c6.4 0 11.8 5.2 11.8 11.8v136.6c-0.4 96.8-79.2 175.8-176.2 175.8z m-152.4-300.8v124.8c0 84 68.4 152.4 152.4 152.4s152.4-68.4 152.4-152.4v-124.8H359.6zM697.6 301.2H326.4c-6.4 0-11.8-5.2-11.8-11.8v-92C314.6 88.6 403.2 0 512 0s197.4 88.6 197.4 197.4v92.2c0 6.4-5.4 11.6-11.8 11.6z m-359.4-23.4H686V197.4c0-95.8-78-173.8-173.8-173.8s-173.8 78-173.8 173.8v80.4z"
      fill="#4C4372"
    />
    <path
      d="M731.2 374.4H292.8c-26.6 0-48.2-21.6-48.2-48.2s21.6-48.2 48.2-48.2h438.6c26.6 0 48.2 21.6 48.2 48.2s-21.6 48.2-48.4 48.2z m-438.4-73.2c-13.6 0-24.8 11.2-24.8 24.8 0 13.6 11.2 24.8 24.8 24.8h438.6c13.6 0 24.8-11.2 24.8-24.8 0-13.6-11.2-24.8-24.8-24.8H292.8z"
      fill="#4C4372"
    />
    <path
      d="M469.8 301.2c-6.4 0-11.8-5.2-11.8-11.8V20.6c0-6.4 5.2-11.8 11.8-11.8s11.8 5.2 11.8 11.8v268.8c0 6.6-5.2 11.8-11.8 11.8zM554.2 301.2c-6.4 0-11.8-5.2-11.8-11.8V20.6c0-6.4 5.2-11.8 11.8-11.8S566 14 566 20.6v268.8c-0.2 6.6-5.4 11.8-11.8 11.8z"
      fill="#4C4372"
    />
  </SvgIcon>
);

export default StuffPageIcon;
