import { AppActionEnums, AppActions, AppStateContext } from "./types";

const reducer = (
  state: AppStateContext,
  action: AppActions
): AppStateContext => {
  switch (action.type) {
    case AppActionEnums.SET_IS_AUTH:
      return {
        ...state,
        isAuth: action.payload,
      };
    case AppActionEnums.SET_APPEARANCE:
      return {
        ...state,
        appearance: action.payload,
      };
    case AppActionEnums.SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case AppActionEnums.SET_SELECTED_DEVICE_ID:
      return {
        ...state,
        selectedDeviceIdInStore: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
