import { ApiGetDataResponse } from "./common";

export enum UserRoleEnum {
  ADMIN = "admin",
  TECHNICIAN = "technician",
}

export interface UserData {
  role: UserRoleEnum;
  email: string;
  _id: string;
  fullName: string;
  phoneNumber: string;
  updatedAt: string;
}

export type UsersEnumList = UsersListItem[];

export type UsersEnumResponse = Promise<UsersEnumList>;

export interface UsersListItem extends Omit<UserData, "phoneNumber"> {
  createdAt: string;
  updatedAt: string;
}

export type UsersListData = ApiGetDataResponse<UsersListItem>;

export type UsersListResponse = Promise<UsersListData>;

export interface UserByIdData extends UserData {
  createdAt: string;
  updatedAt: string;
}

export type UserByIdResponse = Promise<UserByIdData>;

export interface UserCreateParams extends Omit<UserData, "_id" | "updatedAt"> {
  password: string;
}

export type UserCreateResponse = Promise<UserByIdData>;

export interface UserUpdateParams
  extends Omit<UserData, "role" | "_id" | "email" | "updatedAt"> {
  email?: string;
}

export enum UsersRoleFilterEnum {
  ADMIN = "admin",
  TECHNICIAN = "technician",
}

export interface UserCurrentUpdateParams {
  fullName: string;
  phoneNumber: string;
}

export interface ChangePasswordParams {
  newPassword: string;
  confirmPassword: string;
}
