import { SvgIconProps, SvgIcon } from "@mui/material";
import React from "react";

const DevicesPageIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon
    className="svg-icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M512 512m-414 0a414 414 0 1 0 828 0 414 414 0 1 0-828 0Z"
      fill="#F0C48A"
    />
    <path
      d="M512 279.6c-6.4 0-11.8-5.2-11.8-11.8V84.2c0-6.4 5.2-11.8 11.8-11.8 6.4 0 11.8 5.2 11.8 11.8v183.6c0 6.6-5.4 11.8-11.8 11.8zM362.2 279.6c-6.4 0-11.8-5.2-11.8-11.8V84.2c0-6.4 5.2-11.8 11.8-11.8 6.4 0 11.8 5.2 11.8 11.8v183.6c-0.2 6.6-5.4 11.8-11.8 11.8zM661.8 279.6c-6.4 0-11.8-5.2-11.8-11.8V84.2c0-6.4 5.2-11.8 11.8-11.8 6.4 0 11.8 5.2 11.8 11.8v183.6c0 6.6-5.2 11.8-11.8 11.8zM512 951.4c-6.4 0-11.8-5.2-11.8-11.8V756c0-6.4 5.2-11.8 11.8-11.8 6.4 0 11.8 5.2 11.8 11.8v183.6c0 6.6-5.4 11.8-11.8 11.8zM362.2 951.4c-6.4 0-11.8-5.2-11.8-11.8V756c0-6.4 5.2-11.8 11.8-11.8 6.4 0 11.8 5.2 11.8 11.8v183.6c-0.2 6.6-5.4 11.8-11.8 11.8zM661.8 951.4c-6.4 0-11.8-5.2-11.8-11.8V756c0-6.4 5.2-11.8 11.8-11.8 6.4 0 11.8 5.2 11.8 11.8v183.6c0 6.6-5.4 11.8-11.8 11.8zM939.8 523.8h-183.6c-6.4 0-11.8-5.2-11.8-11.8 0-6.4 5.2-11.8 11.8-11.8h183.6c6.4 0 11.8 5.2 11.8 11.8-0.2 6.4-5.4 11.8-11.8 11.8zM939.8 374h-183.6c-6.4 0-11.8-5.2-11.8-11.8 0-6.4 5.2-11.8 11.8-11.8h183.6c6.4 0 11.8 5.2 11.8 11.8-0.2 6.4-5.4 11.8-11.8 11.8zM939.8 673.6h-183.6c-6.4 0-11.8-5.2-11.8-11.8 0-6.4 5.2-11.8 11.8-11.8h183.6c6.4 0 11.8 5.2 11.8 11.8-0.2 6.6-5.4 11.8-11.8 11.8zM268 523.8H84.4c-6.4 0-11.8-5.2-11.8-11.8 0-6.4 5.2-11.8 11.8-11.8H268c6.4 0 11.8 5.2 11.8 11.8-0.2 6.4-5.4 11.8-11.8 11.8zM268 373.8H84.4c-6.4 0-11.8-5.2-11.8-11.8 0-6.4 5.2-11.8 11.8-11.8H268c6.4 0 11.8 5.2 11.8 11.8-0.2 6.6-5.4 11.8-11.8 11.8zM268 673.6H84.4c-6.4 0-11.8-5.2-11.8-11.8 0-6.4 5.2-11.8 11.8-11.8H268c6.4 0 11.8 5.2 11.8 11.8-0.2 6.6-5.4 11.8-11.8 11.8z"
      fill="#4C4372"
    />
    <path
      d="M550.6 973.6c0-21.4-17.4-38.6-38.6-38.6-21.4 0-38.6 17.4-38.6 38.6 0 21.4 17.4 38.6 38.6 38.6 21.4 0 38.6-17.2 38.6-38.6z"
      fill="#7BABF1"
    />
    <path
      d="M324.420551 981.463848a38.6 38.6 0 1 0 75.573243-15.764673 38.6 38.6 0 1 0-75.573243 15.764673Z"
      fill="#7BABF1"
    />
    <path
      d="M700.4 973.6c0-21.4-17.4-38.6-38.6-38.6-21.4 0-38.6 17.4-38.6 38.6 0 21.4 17.4 38.6 38.6 38.6 21.4 0 38.6-17.2 38.6-38.6z"
      fill="#7BABF1"
    />
    <path
      d="M512 1024c-27.8 0-50.4-22.6-50.4-50.4 0-13.4 5.2-26.2 14.8-35.6 9.6-9.6 22.2-14.8 35.6-14.8 27.8 0 50.4 22.6 50.4 50.4 0 27.8-22.6 50.4-50.4 50.4z m0-77.4c-7.2 0-14 2.8-19 7.8s-7.8 11.8-7.8 19c0 14.8 12 27 27 27 14.8 0 27-12 27-27-0.2-14.6-12.4-26.8-27.2-26.8z m38.6 27zM362.2 1024c-27.8 0-50.4-22.6-50.4-50.4 0-27.8 22.6-50.4 50.4-50.4 27.8 0 50.4 22.6 50.4 50.4 0 13.4-5.2 26.2-14.8 35.6-9.6 9.6-22.2 14.8-35.6 14.8z m0-77.4c-14.8 0-27 12-27 27 0 14.8 12 27 27 27 7.2 0 14-2.8 19-7.8s7.8-11.8 7.8-19h11.8-11.8c0-15-12-27.2-26.8-27.2zM661.8 1024c-13.4 0-26.2-5.2-35.6-14.8-9.6-9.6-14.8-22.2-14.8-35.6 0-13.4 5.2-26.2 14.8-35.6 9.6-9.6 22.2-14.8 35.6-14.8 13.4 0 26.2 5.2 35.6 14.8 9.6 9.6 14.8 22.2 14.8 35.6 0 13.4-5.2 26.2-14.8 35.6-9.4 9.6-22.2 14.8-35.6 14.8z m0-77.4c-7.2 0-14 2.8-19 7.8s-7.8 11.8-7.8 19c0 7.2 2.8 14 7.8 19s11.8 7.8 19 7.8c7.2 0 14-2.8 19-7.8s7.8-11.8 7.8-19c0-7.2-2.8-14-7.8-19s-11.8-7.8-19-7.8z"
      fill="#4C4372"
    />
    <path
      d="M550.6 50.4c0 21.4-17.4 38.6-38.6 38.6-21.4 0-38.6-17.4-38.6-38.6 0-21.4 17.4-38.6 38.6-38.6 21.4 0 38.6 17.2 38.6 38.6zM400.8 50.4c0 21.4-17.4 38.6-38.6 38.6-21.4 0-38.6-17.4-38.6-38.6 0-21.4 17.4-38.6 38.6-38.6 21.4 0 38.6 17.2 38.6 38.6zM700.6 50.4c0 21.4-17.4 38.6-38.6 38.6-21.4 0-38.6-17.4-38.6-38.6 0-21.4 17.4-38.6 38.6-38.6 21.2 0 38.6 17.2 38.6 38.6z"
      fill="#7BABF1"
    />
    <path
      d="M512 100.8c-27.8 0-50.4-22.6-50.4-50.4 0-13.4 5.2-26.2 14.8-35.6C486 5.2 498.6 0 512 0c13.4 0 26.2 5.2 35.6 14.8 9.6 9.6 14.8 22.2 14.8 35.6 0 13.4-5.2 26.2-14.8 35.6-9.4 9.6-22.2 14.8-35.6 14.8z m0-77.4c-7.2 0-14 2.8-19 7.8s-7.8 11.8-7.8 19c0 14.8 12 27 27 27 7.2 0 14-2.8 19-7.8s7.8-11.8 7.8-19c0-7.2-2.8-14-7.8-19-5.2-5.2-12-8-19.2-8zM362.2 100.8c-13.4 0-26.2-5.2-35.6-14.8-9.6-9.6-14.8-22.2-14.8-35.6 0-13.4 5.2-26.2 14.8-35.6C336 5.2 348.8 0 362.2 0c27.8 0 50.4 22.6 50.4 50.4 0 27.8-22.6 50.4-50.4 50.4z m0-77.4c-7.2 0-14 2.8-19 7.8s-7.8 11.8-7.8 19c0 7.2 2.8 14 7.8 19s11.8 7.8 19 7.8c14.8 0 27-12 27-27 0-14.4-12.2-26.6-27-26.6z m38.6 27zM661.8 100.8c-27.8 0-50.4-22.6-50.4-50.4C611.4 22.6 634 0 661.8 0c13.4 0 26.2 5.2 35.6 14.8 9.6 9.6 14.8 22.2 14.8 35.6 0 13.4-5.2 26.2-14.8 35.6-9.4 9.6-22 14.8-35.6 14.8z m0-77.4c-14.8 0-27 12-27 27 0 14.8 12 27 27 27 7.2 0 14-2.8 19-7.8s7.8-11.8 7.8-19c0-7.2-2.8-14-7.8-19-5-5.4-11.8-8.2-19-8.2z"
      fill="#4C4372"
    />
    <path
      d="M973.6 550.6c-21.4 0-38.6-17.4-38.6-38.6 0-21.4 17.4-38.6 38.6-38.6 21.4 0 38.6 17.4 38.6 38.6 0 21.4-17.2 38.6-38.6 38.6zM973.6 400.8c-21.4 0-38.6-17.4-38.6-38.6 0-21.4 17.4-38.6 38.6-38.6 21.4 0 38.6 17.4 38.6 38.6 0 21.4-17.2 38.6-38.6 38.6zM973.6 700.6c-21.4 0-38.6-17.4-38.6-38.6 0-21.4 17.4-38.6 38.6-38.6 21.4 0 38.6 17.4 38.6 38.6 0 21.2-17.2 38.6-38.6 38.6z"
      fill="#7BABF1"
    />
    <path
      d="M973.6 562.4c-13.4 0-26.2-5.2-35.6-14.8s-14.8-22.2-14.8-35.6c0-13.4 5.2-26.2 14.8-35.6 9.6-9.6 22.2-14.8 35.6-14.8 13.4 0 26.2 5.2 35.6 14.8 9.6 9.6 14.8 22.2 14.8 35.6 0 13.4-5.2 26.2-14.8 35.6-9.4 9.6-22.2 14.8-35.6 14.8z m0-77.4c-7.2 0-14 2.8-19 7.8s-7.8 11.8-7.8 19c0 7.2 2.8 14 7.8 19s11.8 7.8 19 7.8c7.2 0 14-2.8 19-7.8s7.8-11.8 7.8-19c0-7.2-2.8-14-7.8-19s-11.8-7.8-19-7.8zM973.6 412.6c-13.4 0-26.2-5.2-35.6-14.8-9.6-9.6-14.8-22.2-14.8-35.6 0-13.4 5.2-26.2 14.8-35.6 9.6-9.6 22.2-14.8 35.6-14.8 27.8 0 50.4 22.6 50.4 50.4 0 27.8-22.6 50.4-50.4 50.4z m0-77.4c-7.2 0-14 2.8-19 7.8s-7.8 11.8-7.8 19c0 7.2 2.8 14 7.8 19s11.8 7.8 19 7.8c14.8 0 27-12 27-27 0-14.4-12.2-26.6-27-26.6zM973.6 712.2c-13.4 0-26.2-5.2-35.6-14.8-9.6-9.6-14.8-22.2-14.8-35.6 0-27.8 22.6-50.4 50.4-50.4 27.8 0 50.4 22.6 50.4 50.4 0 13.4-5.2 26.2-14.8 35.6-9.4 9.6-22.2 14.8-35.6 14.8z m0-23.4c7.2 0 14-2.8 19-7.8s7.8-11.8 7.8-19c0-14.8-12-27-27-27-14.8 0-27 12-27 27 0 7.2 2.8 14 7.8 19 5.4 5 12.2 7.8 19.4 7.8z"
      fill="#4C4372"
    />
    <path
      d="M50.4 550.6c21.4 0 38.6-17.4 38.6-38.6 0-21.4-17.4-38.6-38.6-38.6-21.4 0-38.6 17.4-38.6 38.6 0 21.4 17.2 38.6 38.6 38.6zM50.4 400.8c21.4 0 38.6-17.4 38.6-38.6 0-21.4-17.4-38.6-38.6-38.6-21.4 0-38.6 17.4-38.6 38.6 0 21.2 17.2 38.6 38.6 38.6zM50.4 700.4c21.4 0 38.6-17.4 38.6-38.6 0-21.4-17.4-38.6-38.6-38.6-21.4 0-38.6 17.4-38.6 38.6 0 21.4 17.2 38.6 38.6 38.6z"
      fill="#7BABF1"
    />
    <path
      d="M50.4 562.4c-13.4 0-26.2-5.2-35.6-14.8C5.2 538 0 525.4 0 512c0-13.4 5.2-26.2 14.8-35.6 9.6-9.6 22.2-14.8 35.6-14.8 27.8 0 50.4 22.6 50.4 50.4 0 27.8-22.6 50.4-50.4 50.4z m0-77.4c-7.2 0-14 2.8-19 7.8s-7.8 11.8-7.8 19c0 7.2 2.8 14 7.8 19s11.8 7.8 19 7.8c14.8 0 27-12 27-27 0-14.4-12.2-26.6-27-26.6zM50.4 412.6C22.6 412.6 0 390 0 362.2c0-13.4 5.2-26.2 14.8-35.6 9.6-9.6 22.2-14.8 35.6-14.8 27.8 0 50.4 22.6 50.4 50.4 0 27.8-22.6 50.4-50.4 50.4z m0-23.6c14.8 0 27-12 27-27 0-14.8-12-27-27-27-7.2 0-14 2.8-19 7.8s-7.8 11.8-7.8 19c-0.2 15.2 12 27.2 26.8 27.2zM50.4 712.2c-13.4 0-26.2-5.2-35.6-14.8C5.2 688 0 675.2 0 661.8c0-13.4 5.2-26.2 14.8-35.6 9.6-9.6 22.2-14.8 35.6-14.8 13.4 0 26.2 5.2 35.6 14.8 9.6 9.6 14.8 22.2 14.8 35.6 0 27.8-22.6 50.4-50.4 50.4z m0-77.4c-7.2 0-14 2.8-19 7.8s-7.8 11.8-7.8 19c0 14.8 12 27 27 27 14.8 0 27-12 27-27 0-7.2-2.8-14-7.8-19-5.4-5-12.2-7.8-19.4-7.8z"
      fill="#4C4372"
    />
    <path
      d="M705.8 795.4H320.2c-49.2 0-89.6-40.2-89.6-89.6V320.2c0-49.2 40.2-89.6 89.6-89.6H706c49.2 0 89.6 40.2 89.6 89.6V706c-0.2 49-40.6 89.4-89.8 89.4z"
      fill="#D3E6F8"
    />
    <path
      d="M738.6 787.6c23.8-9.8 48.2-35.8 56.6-68.8l-1.6-415.6c-8.6-31.8-31.4-57.2-55-67v551.4z"
      fill="#7BABF1"
    />
    <path
      d="M705.8 807H320.2c-55.8 0-101.2-45.4-101.2-101.2V320.2c0-55.8 45.4-101.2 101.2-101.2H706c55.8 0 101.2 45.4 101.2 101.2V706c-0.2 55.6-45.6 101-101.4 101zM320.2 242.4c-42.8 0-77.8 34.8-77.8 77.8V706c0 42.8 34.8 77.8 77.8 77.8H706c42.8 0 77.8-34.8 77.8-77.8V320.2c0-42.8-34.8-77.8-77.8-77.8H320.2z"
      fill="#4C4372"
    />
    <path
      d="M619.4 669.4H404.6c-27.4 0-49.8-22.4-49.8-49.8V404.6c0-27.4 22.4-49.8 49.8-49.8h214.8c27.4 0 49.8 22.4 49.8 49.8v214.8c0.2 27.4-22.4 50-49.8 50z"
      fill="#FD919E"
    />
    <path
      d="M619.4 681H404.6c-34 0-61.6-27.6-61.6-61.6V404.6c0-34 27.6-61.6 61.6-61.6h214.8c34 0 61.6 27.6 61.6 61.6v214.8c0 34-27.6 61.6-61.6 61.6zM404.6 366.4c-21 0-38.2 17.2-38.2 38.2v214.8c0 21 17.2 38.2 38.2 38.2h214.8c21 0 38.2-17.2 38.2-38.2V404.6c0-21-17.2-38.2-38.2-38.2H404.6z"
      fill="#4C4372"
    />
  </SvgIcon>
);

export default DevicesPageIcon;
