import { createTheme, Theme, PaletteMode } from "@mui/material";
import colors from "./colors";

const createThemeComponent = (mode: PaletteMode | undefined): Theme =>
  createTheme({
    palette: {
      mode,
      primary: {
        main: colors.primary,
      },
      ...(mode === "dark"
        ? {
            background: {
              default: colors.darkBackground,
            },
          }
        : {
            background: {
              default: colors.white,
            },
          }),
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            fontFamily: '"DM Sans", sans-serif',
            fontWeightBold: 500,
          },
          h1: {
            fontWeight: 500,
            fontSize: 44,
          },
          h2: {
            fontWeight: 500,
            fontSize: 26,
          },
          subtitle1: {
            fontSize: 18,
          },
          subtitle2: {
            fontWeight: "bold",
            fontSize: 18,
          },
          body1: {
            fontSize: 16,
          },
          body2: {
            fontWeight: "bold",
            fontSize: 16,
          },
          caption: {
            fontSize: 14,
          },
        },
      },
      MuiButton: {
        defaultProps: {
          variant: "contained",
        },
        styleOverrides: {
          root: {
            color: colors.white,
            // background: colors.primary,
            textTransform: "none",
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: "outlined",
          fullWidth: true,
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor:
              mode === "dark"
                ? colors.textFieldBackground
                : colors.textFieldLightBackground,
            borderRadius: "4px",
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            background: colors.textFieldBackground,
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            textDecoration: "none",
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            paddingLeft: 0,
            marginLeft: 0,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            "&: hover": {
              background: colors.primaryWithOPacity,
            },
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            "&: hover": {
              background: "none",
            },
          },
        },
      },
    },
  });

export default createThemeComponent;
