import React from "react";
import { RouteProps, Navigate } from "react-router-dom";
import { appLinks } from "./routes";

interface Props extends RouteProps {
  isAuthenticated: boolean;
  children: React.ReactElement;
}

const PublicRoute: React.FC<Props> = ({ isAuthenticated, children }: Props) =>
  !isAuthenticated ? children : <Navigate to={appLinks.index.link} />;

export default PublicRoute;
