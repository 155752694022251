import { SvgIconProps, SvgIcon } from "@mui/material";
import React from "react";

const DeviceTypesPageIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon
    className="svg-icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M512 512m-414 0a414 414 0 1 0 828 0 414 414 0 1 0-828 0Z"
      fill="#F0C48A"
    />
    <path
      d="M89.8 293.6v-22.4c0-38.2 31.2-69.4 69.4-69.4h705.6c38.2 0 69.4 31.2 69.4 69.4V716H89.8V293.6z"
      fill="#D3E6F8"
    />
    <path
      d="M871.6 202.2v513h62.6v-444c0-35.8-27.6-65.6-62.6-69z"
      fill="#7BABF1"
    />
    <path d="M151.4 258h721v458H151.4z" fill="#FFFFFF" />
    <path
      d="M678.2 520.4v-48.2h-42.6c-3.2-16.8-9.8-32.6-19.2-46.4l30-30-34-34-30 30c-13.8-9.2-29.4-15.8-46.4-19.2V330h-48v42.6c-16.8 3.2-32.6 9.8-46.4 19.2l-30-30-34 34 30 30c-9.2 13.8-15.8 29.4-19.2 46.4h-42.6v48.2h42.6c3.2 16.8 9.8 32.6 19.2 46.4l-30 30 34 34 30-30c13.8 9.2 29.4 15.8 46.4 19.2v42.6h48V620c16.8-3.2 32.6-9.8 46.4-19.2l30 30 34-34-30-30c9.2-13.8 15.8-29.4 19.2-46.4h42.6zM511.2 558c-34 0-61.6-27.6-61.6-61.6s27.6-61.6 61.6-61.6 61.6 27.6 61.6 61.6-27.6 61.6-61.6 61.6z"
      fill="#FD919E"
    />
    <path
      d="M511.2 569.8c-40.4 0-73.4-33-73.4-73.4 0-40.4 33-73.4 73.4-73.4 40.4 0 73.4 33 73.4 73.4 0 40.4-33 73.4-73.4 73.4z m0-123.4c-27.6 0-49.8 22.4-49.8 49.8 0 27.6 22.4 49.8 49.8 49.8 27.6 0 49.8-22.4 49.8-49.8s-22.2-49.8-49.8-49.8z"
      fill="#4C4372"
    />
    <path
      d="M536 674.2h-48c-6.4 0-11.8-5.2-11.8-11.8v-33.2c-11.6-3-22.6-7.6-32.8-13.6L420 639c-2.2 2.2-5.2 3.4-8.2 3.4s-6-1.2-8.2-3.4l-34-34c-4.6-4.6-4.6-12 0-16.6l23.4-23.4c-6-10.4-10.6-21.4-13.6-32.8h-33.2c-6.4 0-11.8-5.2-11.8-11.8v-48.2c0-6.4 5.2-11.8 11.8-11.8h33.2c3-11.6 7.6-22.6 13.6-32.8l-23.4-23.4c-4.6-4.6-4.6-12 0-16.6l34-34c4.6-4.6 12-4.6 16.6 0l23.4 23.4c10.4-6 21.4-10.6 32.8-13.6v-33.2c0-6.4 5.2-11.8 11.8-11.8H536c6.4 0 11.8 5.2 11.8 11.8v33.2c11.6 3 22.6 7.6 32.8 13.6l23.4-23.4c4.6-4.6 12-4.6 16.6 0l34 34c4.6 4.6 4.6 12 0 16.6l-23.4 23.4c6 10.4 10.6 21.4 13.6 32.8H678c6.4 0 11.8 5.2 11.8 11.8v48.2c0 6.4-5.2 11.8-11.8 11.8h-33.2c-3 11.6-7.6 22.6-13.6 32.8l23.4 23.4c4.6 4.6 4.6 12 0 16.6l-34 34c-2.2 2.2-5.2 3.4-8.2 3.4-3.2 0-6-1.2-8.2-3.4l-23.4-23.4c-10.4 6-21.4 10.6-32.8 13.6v33.2c-0.2 6.6-5.4 11.8-12 11.8z m-36.4-23.4h24.6V620c0-5.6 4-10.4 9.4-11.6 15.2-3 29.2-8.8 42-17.4 4.6-3.2 10.8-2.6 14.8 1.4l21.8 21.8 17.4-17.4-21.8-21.8c-4-4-4.6-10.2-1.4-14.8 8.6-12.8 14.4-26.8 17.4-42 1-5.6 5.8-9.4 11.6-9.4h30.8V484h-30.8c-5.6 0-10.4-4-11.6-9.4-3-15.2-8.8-29.2-17.4-42-3.2-4.6-2.6-10.8 1.4-14.8l21.8-21.8-17.4-17.4-21.8 21.8c-4 4-10.2 4.6-14.8 1.4-12.8-8.6-26.8-14.4-42-17.4-5.6-1-9.4-5.8-9.4-11.6V342h-24.6v30.8c0 5.6-4 10.4-9.4 11.6-15.2 3-29.2 8.8-42 17.4-4.6 3.2-10.8 2.6-14.8-1.4l-21.8-21.8-17.4 17.4 21.8 21.8c4 4 4.6 10.2 1.4 14.8-8.6 12.8-14.4 26.8-17.4 42-1 5.6-5.8 9.4-11.6 9.4h-30.8v24.6h30.8c5.6 0 10.4 4 11.6 9.4 3 15.2 8.8 29.2 17.4 42 3.2 4.6 2.6 10.8-1.4 14.8l-21.8 21.8 17.4 17.4 21.8-21.8c4-4 10.2-4.6 14.8-1.4 12.8 8.6 26.8 14.4 42 17.4 5.6 1 9.4 5.8 9.4 11.6v31z"
      fill="#4C4372"
    />
    <path
      d="M934.2 727.6H89.8c-6.4 0-11.8-5.2-11.8-11.8V271.2c0-44.6 36.4-81 81-81h705.6c44.6 0 81 36.4 81 81V716c0.2 6.4-5 11.6-11.4 11.6zM101.6 704.2h820.8V271.2c0-31.8-25.8-57.6-57.6-57.6H159.2c-31.8 0-57.6 25.8-57.6 57.6v433z"
      fill="#4C4372"
    />
    <path
      d="M944.2 822.2H79.8c-37.4 0-68-30.6-68-68V716h1000.6v38c-0.2 37.6-30.8 68.2-68.2 68.2z"
      fill="#A4CFF2"
    />
    <path
      d="M13.6 770c6.2 25.6 26.8 45.6 52.6 50.8h891.4c25.8-5.2 46.6-25.2 52.6-50.8H13.6z"
      fill="#7BABF1"
    />
    <path
      d="M944.2 833.8H79.8C35.8 833.8 0 798 0 754v-38c0-6.4 5.2-11.8 11.8-11.8h1000.6c6.4 0 11.8 5.2 11.8 11.8v38c-0.2 44-36 79.8-80 79.8zM23.4 727.6V754c0 31 25.2 56.2 56.2 56.2h864.6c31 0 56.2-25.2 56.2-56.2v-26.4H23.4z"
      fill="#4C4372"
    />
    <path
      d="M872.6 727.6H151.4c-6.4 0-11.8-5.2-11.8-11.8V258c0-6.4 5.2-11.8 11.8-11.8h721c6.4 0 11.8 5.2 11.8 11.8v458c0 6.4-5.2 11.6-11.6 11.6zM163.2 704.2h697.6V269.6H163.2v434.6z"
      fill="#4C4372"
    />
  </SvgIcon>
);

export default DeviceTypesPageIcon;
