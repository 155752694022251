import { Dispatch } from "react";
import { PaletteMode } from "@mui/material";
import { UserData } from "../types/User";

export interface AppStateContext {
  appearance: PaletteMode | undefined;
  isAuth: boolean;
  user: UserData;
  selectedDeviceIdInStore: string | undefined;
}

export enum AppActionEnums {
  SET_APPEARANCE = "SET_APPEARANCE",
  SET_IS_AUTH = "SET_IS_AUTH",
  SET_USER = "SET_USER",
  SET_SELECTED_DEVICE_ID = "SET_SELECTED_DEVICE_ID",
}

export interface IAppContext {
  state: AppStateContext;
  dispatch: Dispatch<AppActions>;
}

interface SetAppearance {
  type: AppActionEnums.SET_APPEARANCE;
  payload: PaletteMode;
}

interface SetIsAuth {
  type: AppActionEnums.SET_IS_AUTH;
  payload: boolean;
}

interface SetUser {
  type: AppActionEnums.SET_USER;
  payload: UserData;
}

interface SetSelectedDeviceId {
  type: AppActionEnums.SET_SELECTED_DEVICE_ID;
  payload: string | undefined;
}

export type AppActions =
  | SetAppearance
  | SetIsAuth
  | SetUser
  | SetSelectedDeviceId;
