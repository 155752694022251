import { PaletteMode } from "@mui/material";
import { UserData } from "../types/User";
import { AppActions, AppActionEnums } from "./types";

const setAppearance = (data: PaletteMode): AppActions => ({
  type: AppActionEnums.SET_APPEARANCE,
  payload: data,
});

const setIsAuth = (isAuth: boolean): AppActions => ({
  type: AppActionEnums.SET_IS_AUTH,
  payload: isAuth,
});

const setUser = (data: UserData): AppActions => ({
  type: AppActionEnums.SET_USER,
  payload: data,
});

const setSelectedDeviceIdToStore = (id: string | undefined): AppActions => ({
  type: AppActionEnums.SET_SELECTED_DEVICE_ID,
  payload: id,
});

export { setAppearance, setIsAuth, setUser, setSelectedDeviceIdToStore };
