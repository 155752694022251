import axios, { AxiosInstance } from "axios";
import { parseJwtToken } from "../helpers/common";
import ENDPOINTS from "./endpoints";
import sessionApi from "./sessionApi";

const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

const setApiAuthorizationHeader = (token: string): void => {
  apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const deleteApiAuthorizationHeader = (): void => {
  delete apiClient.defaults.headers.common.Authorization;
};

const createApiClientResponseInterceptor = (on401Error: () => void): void => {
  const interceptor = apiClient.interceptors.response.use(
    (value) => value,
    (error) => {
      if (error.response.status === 401) {
        on401Error();

        return Promise.reject(error);
      }

      apiClient.interceptors.response.eject(interceptor);

      return Promise.reject(error);
    }
  );
};

const createApiClientRequestInterceptor = (
  callbackOnError: () => void
): void => {
  const interceptorId = apiClient.interceptors.request.use(
    async (value) => {
      if (value.url === `${ENDPOINTS.SESSION}/logout`) {
        apiClient.interceptors.request.eject(interceptorId);
      }

      if (value.headers) {
        const parsedToken = parseJwtToken(
          // eslint-disable-next-line
          // @ts-ignore
          JSON.stringify(value.headers.common.Authorization).split(" ")[1]
        );
        const expDate = new Date(parsedToken.exp * 1000);

        if (expDate < new Date()) {
          await sessionApi
            .refreshToken()
            .then((res) => {
              setApiAuthorizationHeader(res.accessToken);

              if (value.headers) {
                // eslint-disable-next-line
                value.headers.Authorization = `Bearer ${res.accessToken}`;
              } else {
                console.log("NO HEADERS");
              }

              return value;
            })
            .catch((reason) => {
              if (reason.response.status === 404) {
                callbackOnError();
              }
            });
        }
      }

      return value;
    },
    (error) => Promise.reject(error)
  );
};

export {
  apiClient,
  setApiAuthorizationHeader,
  deleteApiAuthorizationHeader,
  createApiClientRequestInterceptor,
  createApiClientResponseInterceptor,
};
