import { CssBaseline, ThemeProvider, useMediaQuery } from "@mui/material";
import React from "react";
import CustomSnackbar from "../components/CustomSnackbar";
import { useAppContext } from "../store";
import createThemeComponent from "../theme/muiThemeConfig";

interface Props {
  children: React.ReactNode;
}

const Theme: React.FC<Props> = (props: Props) => {
  const { children } = props;
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const {
    state: { appearance },
  } = useAppContext();

  const themeConfig = React.useMemo(() => {
    if (appearance) {
      return createThemeComponent(appearance);
    }

    return createThemeComponent(prefersDarkMode ? "dark" : "light");
  }, [prefersDarkMode, appearance]);

  return (
    <ThemeProvider theme={themeConfig}>
      <CustomSnackbar>
        <CssBaseline />
        {children}
      </CustomSnackbar>
    </ThemeProvider>
  );
};

export default Theme;
