import Cookies from "js-cookie";

const COOKIES = {
  REFRESH_TOKEN: "refreshToken",
  ACCESS_TOKEN: "sentinel_access_token",
};

const expiresDate = Date.now() + 180 * 60 * 1000;

const setRefreshTokenToCookies = (token: string): string | undefined =>
  Cookies.set(COOKIES.REFRESH_TOKEN, token, {
    expires: expiresDate,
  });

const getTokenFromCookies = (): string | undefined =>
  Cookies.get(COOKIES.REFRESH_TOKEN);

const removeTokenFromCookies = (): void =>
  Cookies.remove(COOKIES.REFRESH_TOKEN);

const setAccessTokenToCookie = (token: string): string | undefined =>
  Cookies.set(COOKIES.ACCESS_TOKEN, token);

const getAccessTokenFromCookie = (): string | undefined =>
  Cookies.get(COOKIES.ACCESS_TOKEN);

const removeAccessTokenFromCookie = (): void =>
  Cookies.remove(COOKIES.ACCESS_TOKEN);

export {
  setRefreshTokenToCookies,
  getTokenFromCookies,
  removeTokenFromCookies,
  setAccessTokenToCookie,
  getAccessTokenFromCookie,
  removeAccessTokenFromCookie,
};
