import {
  UsersListResponse,
  UsersListData,
  UserByIdResponse,
  UserByIdData,
  UserCreateParams,
  UserCreateResponse,
  UserUpdateParams,
  UserCurrentUpdateParams,
  UsersEnumResponse,
  UsersEnumList,
  UsersRoleFilterEnum,
} from "../types/User";
import { ApiPaginationParams } from "../types/common";
import ENDPOINTS from "./endpoints";
import { apiClient } from "./apiClient";

const endpoint = ENDPOINTS.USER;

const getUsersList = (params: ApiPaginationParams): UsersListResponse =>
  apiClient.get<UsersListData>(endpoint, { params }).then((res) => res.data);

const getUsersListEnum = (): UsersEnumResponse =>
  apiClient
    .get<UsersEnumList>(
      `${endpoint}/list?role=${UsersRoleFilterEnum.TECHNICIAN}`
    )
    .then((res) => res.data);

const getUserById = (id: string): UserByIdResponse =>
  apiClient.get<UserByIdData>(`${endpoint}/${id}`).then((res) => res.data);

const getCurrentUser = (): UserByIdResponse =>
  apiClient.get<UserByIdData>(`${endpoint}/me`).then((res) => res.data);

const updateCurrentUser = (
  params: UserCurrentUpdateParams
): UserCreateResponse =>
  apiClient.put<UserByIdData>(`${endpoint}/me`, params).then((res) => res.data);

const updatePassword = (password: string): Promise<void> =>
  apiClient
    .patch<void>(`${endpoint}/me/password`, { password })
    .then((res) => res.data);

const createUser = (params: UserCreateParams): UserCreateResponse =>
  apiClient.post<UserByIdData>(endpoint, params).then((res) => res.data);

const updateUser = (id: string, params: UserUpdateParams): UserCreateResponse =>
  apiClient
    .put<UserByIdData>(`${endpoint}/${id}`, params)
    .then((res) => res.data);

const deleteUser = (id: string): Promise<void> =>
  apiClient.delete<void>(`${endpoint}/${id}`).then((res) => res.data);

export default {
  getUsersList,
  getUsersListEnum,
  getUserById,
  getCurrentUser,
  updateCurrentUser,
  updatePassword,
  createUser,
  updateUser,
  deleteUser,
};
