import { SvgIconProps, SvgIcon } from "@mui/material";
import React from "react";

const DeviceLogsPageIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon
    className="svg-icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M512 512m-414 0a414 414 0 1 0 828 0 414 414 0 1 0-828 0Z"
      fill="#F0C48A"
    />
    <path
      d="M780.2 776H243.8c-56.8 0-102.8-46-102.8-102.8V155.8h742v517.4c0 56.6-46 102.8-102.8 102.8z"
      fill="#FFFFFF"
    />
    <path
      d="M205.6 407.2h99.2v261.8h-99.2zM348.8 324.2H448v344.8h-99.2zM492 241h99.2v427.8H492z"
      fill="#FD919E"
    />
    <path
      d="M802.8 776c44.2 0 79.8-35.8 79.8-79.8V155.8h-79.8V776z"
      fill="#D3E6F8"
    />
    <path
      d="M932.2 155.8H91.8c-39.8 0-72-32.2-72-72s32.2-72 72-72h840.6c39.8 0 72 32.2 72 72s-32.4 72-72.2 72z"
      fill="#A4CFF2"
    />
    <path
      d="M932.2 155.8h-129.6v-144h129.6c39.8 0 72 32.2 72 72 0.2 39.8-32.2 72-72 72z"
      fill="#7BABF1"
    />
    <path
      d="M457.509028 975.764414a59 59 0 1 0 109.017785-45.156645 59 59 0 1 0-109.017785 45.156645Z"
      fill="#7BABF1"
    />
    <path
      d="M932.2 167.6H91.8C45.6 167.6 8 130 8 83.8S45.6 0 91.8 0h840.6c46 0 83.6 37.6 83.6 83.8s-37.6 83.8-83.8 83.8zM91.8 23.4c-33.2 0-60.4 27-60.4 60.4C31.4 117 58.4 144 91.8 144h840.6c33.2 0 60.4-27 60.4-60.4 0-33.2-27-60.4-60.4-60.4H91.8z"
      fill="#4C4372"
    />
    <path
      d="M780.2 787.6H243.8c-63.2 0-114.6-51.4-114.6-114.6V155.8c0-6.4 5.2-11.8 11.8-11.8h742c6.4 0 11.8 5.2 11.8 11.8v517.4c0 63-51.4 114.4-114.6 114.4zM152.8 167.6v505.6c0 50.2 40.8 91 91 91h536.4c50.2 0 91-40.8 91-91V167.6H152.8z"
      fill="#4C4372"
    />
    <path
      d="M512 905.8c-6.4 0-11.8-5.2-11.8-11.8v-118c0-6.4 5.2-11.8 11.8-11.8 6.4 0 11.8 5.2 11.8 11.8v118.2c0 6.4-5.4 11.6-11.8 11.6z"
      fill="#4C4372"
    />
    <path
      d="M512 1024c-39 0-70.8-31.8-70.8-70.8s31.8-70.8 70.8-70.8 70.8 31.8 70.8 70.8S551 1024 512 1024z m0-118.2c-26.2 0-47.4 21.2-47.4 47.4s21.2 47.4 47.4 47.4 47.4-21.2 47.4-47.4-21.2-47.4-47.4-47.4zM304.8 680.6h-99.2c-6.4 0-11.8-5.2-11.8-11.8V407.2c0-6.4 5.2-11.8 11.8-11.8h99.2c6.4 0 11.8 5.2 11.8 11.8v261.8c0 6.4-5.4 11.6-11.8 11.6z m-87.6-23.4h75.8V419h-75.8v238.2zM448 680.6h-99.2c-6.4 0-11.8-5.2-11.8-11.8V324.2c0-6.4 5.2-11.8 11.8-11.8H448c6.4 0 11.8 5.2 11.8 11.8v344.8c0 6.4-5.2 11.6-11.8 11.6z m-87.4-23.4h75.8V335.8h-75.8v321.4zM591.2 680.6H492c-6.4 0-11.8-5.2-11.8-11.8V241c0-6.4 5.2-11.8 11.8-11.8h99.2c6.4 0 11.8 5.2 11.8 11.8v427.8c0 6.6-5.2 11.8-11.8 11.8z m-87.4-23.4h75.8V252.8h-75.8v404.4zM818.4 480h-147.8c-6.4 0-11.8-5.2-11.8-11.8s5.2-11.8 11.8-11.8h147.8c6.4 0 11.8 5.2 11.8 11.8s-5.2 11.8-11.8 11.8zM818.4 546.8h-147.8c-6.4 0-11.8-5.2-11.8-11.8 0-6.4 5.2-11.8 11.8-11.8h147.8c6.4 0 11.8 5.2 11.8 11.8 0 6.6-5.2 11.8-11.8 11.8zM818.4 613.8h-147.8c-6.4 0-11.8-5.2-11.8-11.8 0-6.4 5.2-11.8 11.8-11.8h147.8c6.4 0 11.8 5.2 11.8 11.8 0 6.6-5.2 11.8-11.8 11.8zM763 680.6h-92.4c-6.4 0-11.8-5.2-11.8-11.8 0-6.4 5.2-11.8 11.8-11.8h92.4c6.4 0 11.8 5.2 11.8 11.8 0 6.6-5.2 11.8-11.8 11.8z"
      fill="#4C4372"
    />
  </SvgIcon>
);

export default DeviceLogsPageIcon;
