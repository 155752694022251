import { SvgIconProps, SvgIcon } from "@mui/material";
import React from "react";

const SettingsPageIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon
    className="svg-icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M512 512m-414 0a414 414 0 1 0 828 0 414 414 0 1 0-828 0Z"
      fill="#F0C48A"
    />
    <path
      d="M561.8 405.2v-79.8h-70.4c-5.4-28-16.4-54-31.8-76.6l49.8-49.8-56.4-56.4-49.8 49.8c-22.8-15.4-48.8-26.4-76.6-31.8V90.4h-79.8v70.4c-28 5.4-54 16.4-76.6 31.8L120.4 142.8 64 199.2l49.8 49.8c-15.4 22.8-26.4 48.8-31.8 76.6H11.8v79.8h70.4c5.4 28 16.4 54 31.8 76.6l-50 49.6L120.4 588l49.8-49.8c22.8 15.4 48.8 26.4 76.6 31.8v70.4h79.8V570c28-5.4 54-16.4 76.6-31.8l49.8 49.8 56.4-56.4-49.8-49.6c15.4-22.8 26.4-48.8 31.8-76.6h70.4z m-276.4 62.2c-56.4 0-102-45.6-102-102s45.6-102 102-102 102 45.6 102 102-45.6 102-102 102z"
      fill="#FD919E"
    />
    <path
      d="M426 365.4c0-77.6-63-140.6-140.6-140.6s-140.6 63-140.6 140.6 63 140.6 140.6 140.6 140.6-63 140.6-140.6z m-140.6 102c-56.4 0-102-45.6-102-102s45.6-102 102-102 102 45.6 102 102-45.6 102-102 102z"
      fill="#E8677D"
    />
    <path
      d="M285.4 479.2c-62.8 0-113.8-51-113.8-113.8s51-113.8 113.8-113.8 113.8 51 113.8 113.8-51 113.8-113.8 113.8z m0-204.2c-49.8 0-90.2 40.6-90.2 90.2s40.6 90.2 90.2 90.2 90.2-40.6 90.2-90.2-40.4-90.2-90.2-90.2z"
      fill="#4C4372"
    />
    <path
      d="M326.6 652.2h-79.8c-6.4 0-11.8-5.2-11.8-11.8v-61c-22.4-5.4-43.6-14.2-63.4-26.2l-43 43c-2.2 2.2-5.2 3.4-8.2 3.4-3.2 0-6-1.2-8.2-3.4L55.8 540c-4.6-4.6-4.6-12 0-16.6l43-43c-12-19.6-20.8-40.8-26.2-63.4H11.8c-6.4 0-11.8-5.2-11.8-11.8v-79.8c0-6.4 5.2-11.8 11.8-11.8h61c4.8-19.6 12.2-38.6 22-56.2l4.2-7.2L56 207.2c-4.6-4.6-4.6-12 0-16.6l56.4-56.4c4.6-4.6 12-4.6 16.6 0L172 177.2c19.6-12 40.8-20.8 63.4-26.2V90.4c0-6.4 5.2-11.8 11.8-11.8h79.8c6.4 0 11.8 5.2 11.8 11.8v61c22.4 5.4 43.6 14.2 63.4 26.2l43-43c4.6-4.6 12-4.6 16.6 0l56.4 56.4c2.2 2.2 3.4 5.2 3.4 8.2 0 3.2-1.2 6-3.4 8.2l-43 43c12 19.6 20.8 40.8 26.2 63.4h61c6.4 0 11.8 5.2 11.8 11.8v79.8c0 6.4-5.2 11.8-11.8 11.8h-61c-5.4 22.4-14.2 43.6-26.2 63.4l43 43c2.2 2.2 3.4 5.2 3.4 8.2s-1.2 6-3.4 8.2l-56.4 56.4c-2.2 2.2-5.2 3.4-8.2 3.4-3.2 0-6-1.2-8.2-3.4l-43-43c-19.6 12-40.8 20.8-63.4 26.2v61c-0.6 6.2-6 11.6-12.4 11.6z m-68-23.6h56.2V570c0-5.6 4-10.4 9.4-11.6 26-5 50.4-15.2 72.4-30 4.6-3.2 10.8-2.6 14.8 1.4l41.4 41.4 39.8-39.8-41.4-41.4c-4-4-4.6-10.2-1.4-14.8 14.8-22 24.8-46.2 30-72.4 1-5.6 5.8-9.4 11.6-9.4H550v-56.2h-58.6c-5.6 0-10.4-4-11.6-9.4-5-26-15.2-50.4-30-72.4-3.2-4.6-2.6-10.8 1.4-14.8l41.4-41.4-39.8-39.8-41.4 41.4c-4 4-10.2 4.6-14.8 1.4-22-14.8-46.2-24.8-72.4-30-5.6-1-9.4-5.8-9.4-11.6V102h-56.2v58.6c0 5.6-4 10.4-9.4 11.6-26 5-50.4 15.2-72.4 30-4.6 3.2-10.8 2.6-14.8-1.4L120.4 159.4 80.6 199.2 122 240.6c4 4 4.6 10.2 1.4 14.8-3 4.4-5.8 9-8.4 13.6-10.2 18.2-17.4 38-21.4 58.6-1 5.6-5.8 9.4-11.6 9.4H23.4v56.2H82c5.6 0 10.4 4 11.6 9.4 5 26 15.2 50.4 30 72.4 3.2 4.6 2.6 10.8-1.4 14.8l-41.4 41.4 39.8 39.8L162 530c4-4 10.2-4.6 14.8-1.4 22 14.8 46.2 24.8 72.4 30 5.6 1 9.4 5.8 9.4 11.6v58.4z"
      fill="#4C4372"
    />
    <path
      d="M1012.2 698.4v-79.8h-70.4c-5.4-28-16.4-54-31.8-76.6l49.8-49.8-56.2-56.2-49.8 49.8c-22.8-15.4-48.8-26.4-76.6-31.8v-70.4h-79.8V454c-28 5.4-54 16.4-76.6 31.8L571 436l-56.4 56.4 49.8 49.8c-15.4 22.8-26.4 48.8-31.8 76.6h-70.4v79.8h70.4c5.4 28 16.4 54 31.8 76.6l-49.8 49.8 56.4 56.4 49.8-49.8c22.8 15.4 48.8 26.4 76.6 31.8v70.4h79.8v-70.4c28-5.4 54-16.4 76.6-31.8l49.8 49.8 56.4-56.4-49.8-49.8c15.4-22.8 26.4-48.8 31.8-76.6h70.2zM736 760.6c-56.4 0-102-45.6-102-102s45.6-102 102-102 102 45.6 102 102-45.8 102-102 102z"
      fill="#A4CFF2"
    />
    <path
      d="M876.6 658.6c0-77.6-63-140.6-140.6-140.6s-140.6 63-140.6 140.6 63 140.6 140.6 140.6 140.6-63 140.6-140.6z m-140.6 102c-56.4 0-102-45.6-102-102s45.6-102 102-102 102 45.6 102 102-45.8 102-102 102z"
      fill="#7BABF1"
    />
    <path
      d="M736 772.4c-62.8 0-113.8-51-113.8-113.8s51-113.8 113.8-113.8 113.8 51 113.8 113.8-51.2 113.8-113.8 113.8z m0-204c-49.8 0-90.2 40.6-90.2 90.2 0 49.8 40.6 90.2 90.2 90.2s90.2-40.6 90.2-90.2c0-49.8-40.4-90.2-90.2-90.2z"
      fill="#4C4372"
    />
    <path
      d="M777.2 945.4h-79.8c-6.4 0-11.8-5.2-11.8-11.8v-61c-22.4-5.4-43.6-14.2-63.4-26.2l-43 43c-2.2 2.2-5.2 3.4-8.2 3.4-3.2 0-6-1.2-8.2-3.4l-56.4-56.4c-4.6-4.6-4.6-12 0-16.6l43-43c-12-19.6-20.8-40.8-26.2-63.4h-61c-6.4 0-11.8-5.2-11.8-11.8v-79.8c0-6.4 5.2-11.8 11.8-11.8h61c4.8-19.6 12.2-38.6 22-56.2l4.2-7.2-43-43c-4.6-4.6-4.6-12 0-16.6l56.4-56.4c4.6-4.6 12-4.6 16.6 0l43 43c19.6-12 40.8-20.8 63.4-26.2v-61c0-6.4 5.2-11.8 11.8-11.8h79.8c6.4 0 11.8 5.2 11.8 11.8V444c22.4 5.4 43.6 14.2 63.4 26.2l43-43c4.6-4.6 12-4.6 16.6 0l56.4 56.4c2.2 2.2 3.4 5.2 3.4 8.2s-1.2 6-3.4 8.2l-43 43c12 19.6 20.8 40.8 26.2 63.4h61c6.4 0 11.8 5.2 11.8 11.8V698c0 6.4-5.2 11.8-11.8 11.8h-61c-5.4 22.4-14.2 43.6-26.2 63.4l43 43c4.6 4.6 4.6 12 0 16.6l-56.4 56.4c-2.2 2.2-5.2 3.4-8.2 3.4-3.2 0-6-1.2-8.2-3.4l-43-43c-19.6 12-40.8 20.8-63.4 26.2v61c-0.6 6.8-5.8 12-12.2 12z m-68-23.4h56.2v-58.6c0-5.6 4-10.4 9.4-11.6 26-5 50.4-15.2 72.4-30 4.6-3.2 10.8-2.6 14.8 1.4l41.4 41.4 39.8-39.8-41.4-41.4c-4-4-4.6-10.2-1.4-14.8 14.8-22 24.8-46.2 30-72.4 1-5.6 5.8-9.4 11.6-9.4h58.6v-56.2H942c-5.6 0-10.4-4-11.6-9.4-5-26-15.2-50.4-30-72.4-3.2-4.6-2.6-10.8 1.4-14.8l41.4-41.4-39.8-39.8L862 494c-4 4-10.2 4.6-14.8 1.4-22-14.8-46.2-24.8-72.4-30-5.6-1-9.4-5.8-9.4-11.6v-58.6h-56.2V454c0 5.6-4 10.4-9.4 11.6-26 5-50.4 15.2-72.4 30-4.6 3.2-10.8 2.6-14.8-1.4l-41.4-41.4-39.8 39.8 41.4 41.4c4 4 4.6 10.2 1.4 14.8-3 4.4-5.8 9-8.4 13.6-10.2 18.2-17.4 38-21.4 58.6-1 5.6-5.8 9.4-11.6 9.4H474v56.2h58.6c5.6 0 10.4 4 11.6 9.4 5 26 15.2 50.4 30 72.4 3.2 4.6 2.6 10.8-1.4 14.8l-41.4 41.4 39.8 39.8 41.4-41.4c4-4 10.2-4.6 14.8-1.4 22 14.8 46.2 24.8 72.4 30 5.6 1 9.4 5.8 9.4 11.6V922z"
      fill="#4C4372"
    />
  </SvgIcon>
);

export default SettingsPageIcon;
