import { ParsedJwtToken } from "../types/Session";

const parseJwtToken = (token: string): ParsedJwtToken => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );

  return JSON.parse(jsonPayload);
};

const convertHoursMinutesToDate = (time: string): Date => {
  return new Date(`01/01/1970 ${time}`);
};

const getTrimmedString = (d: string, length = 15): string =>
  d.length > length ? `${d.substr(0, length)}...` : d;

export { parseJwtToken, convertHoursMinutesToDate, getTrimmedString };
