import { SingInParams, SingInResponse } from "../types/Session";
import { apiClient } from "./apiClient";
import ENDPOINTS from "./endpoints";

const endpoint = ENDPOINTS.SESSION;
const refreshTokenEndpoint = `${process.env.REACT_APP_API_BASE_URL}sessions`;

const signIn = (params: SingInParams): SingInResponse =>
  fetch(`${refreshTokenEndpoint}/sign-in`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());

const refreshToken = () =>
  fetch(`${refreshTokenEndpoint}/refresh-token`, {
    credentials: "include",
    method: "POST",
  }).then((res) => res.json());

const logOut = (): Promise<void> =>
  apiClient.post<void>(`${endpoint}/log-out`).then((res) => res.data);

export default {
  signIn,
  refreshToken,
  logOut,
};
