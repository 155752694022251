import { SvgIconProps, SvgIcon } from "@mui/material";
import React from "react";

const EyeOpenIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g stroke="currentColor" strokeWidth="1.5">
        <g>
          <g transform="translate(-924 -254) translate(924 254) translate(3 5)">
            <path d="M0 6.545S3.273 0 9 0s9 6.545 9 6.545-3.273 6.546-9 6.546-9-6.546-9-6.546z" />
            <circle cx="9" cy="6.545" r="2.455" />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default EyeOpenIcon;
