import { Box } from "@mui/material";
import React from "react";
import RouterWrapper from "../routes/RouterWrapper";
import { AppStoreProvider } from "../store";
import Theme from "./Theme";
import UserDataWrapper from "./UserDataWrapper";

const App: React.FC = () => {
  return (
    <AppStoreProvider>
      <Theme>
        <UserDataWrapper>
          <Box sx={{ padding: "15px 25px 10px 25px" }}>
            <RouterWrapper />
          </Box>
        </UserDataWrapper>
      </Theme>
    </AppStoreProvider>
  );
};

export default App;
