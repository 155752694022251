import { SvgIconProps, SvgIcon } from "@mui/material";
import React from "react";

const LocationsPageIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon
    className="svg-icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M512 512m-414 0a414 414 0 1 0 828 0 414 414 0 1 0-828 0Z"
      fill="#F0C48A"
    />
    <path
      d="M678.8 164.8l-333.6 134.8L11.8 164.8v559.6l333.4 134.8 333.6-134.8 333.4 134.8V299.6z"
      fill="#FFFFFF"
    />
    <path
      d="M46.4 226.6v468.6l299 120 335.4-134.4 296.6 122.4V329.8L680.4 210l-332.8 134.2z"
      fill="#7BABF1"
    />
    <path
      d="M54 512.6s59.6 153.2 31.4 198.2l-39-15.6 7.6-182.6zM977.4 622.8V329.8L680.4 210l-332.8 134.2L137.2 262c-72.4 153.6 93 313.2 93 313.2 81.6 87.6 156.8 151.4 225.6 196l225-90.2L892 768c37.4-40 65.8-90.8 85.4-145.2z"
      fill="#A4CFF2"
    />
    <path
      d="M561.4 310c-43 38.8-83.4 19.6-122.8-2.6L347.6 344l-59.4-23.2c-72 108 52.2 227.8 52.2 227.8 90.6 97.2 169.4 150.8 236.2 174l104.2-41.8 66.8 27.6c90.2-58.8 127-198.4 110.8-296.4-31.6-191.8-202.2-187.4-297-102z"
      fill="#D3E6F8"
    />
    <path
      d="M345.2 871c-1.4 0-3-0.2-4.4-0.8L7.4 735.2c-4.4-1.8-7.4-6-7.4-10.8V164.8c0-3.8 2-7.6 5.2-9.8 3.2-2.2 7.4-2.6 11-1.2l329.2 133L674.4 154c2.8-1.2 6-1.2 8.8 0l333.6 134.8c4.4 1.8 7.4 6 7.4 10.8v559.6c0 3.8-2 7.6-5.2 9.8-3.2 2.2-7.4 2.6-11 1.2l-329.2-133L349.6 870c-1.4 0.6-2.8 1-4.4 1zM23.4 716.4l321.8 130 329.2-133c2.8-1.2 6-1.2 8.8 0l317.4 128.4V307.6l-321.8-130-329.2 133c-2.8 1.2-6 1.2-8.8 0L23.4 182.2v534.2z"
      fill="#4C4372"
    />
    <path
      d="M456.6 520.8s-63.6-61.4-21.2-114.6c42.4-53.2 81.8 41 127.4 0 45.4-41 127.4-43.2 142.6 49s-66.8 260.6-248.8 65.6z"
      fill="#E8677D"
    />
    <path
      d="M624.6 672.2c-53.6 0-127-30.2-222.2-132.2-2.8-2.6-24.8-24.8-40-56.4-22-45.4-18.8-87.8 9-122.6 37.6-47 76.4-25.2 107.4-7.6 31.4 17.8 51.8 27.6 75.6 6.2 41.2-37.2 97.2-51 142.4-35.2 42.4 14.8 71 54.6 80.4 111.8 12.8 78.4-19.6 185.6-94.8 222.6-15.8 7.8-35 13.4-57.8 13.4zM422.2 356.4c-11.2 0-21.4 5.4-32.4 19.4-22 27.6-24.2 60.4-6.2 97.8 14 29.2 35.2 49.8 35.4 50l0.4 0.4c54 58 104.8 96.2 150.8 113.8 38.2 14.6 72.4 14.6 101.8 0.2 63.4-31.2 93.6-127.4 82-197.8-8-49-30.6-81.4-65-93.4-36.6-12.8-84.4-0.6-118.8 30.4-37.6 33.8-73.8 13.4-102.8-3.2-18.2-10.2-32.4-17.6-45.2-17.6z"
      fill="#4C4372"
    />
    <path
      d="M513.2 507.2s-30-29-10-54 38.6 19.4 60 0 60-20.4 67.2 23c7.2 43.4-31.4 123-117.2 31z"
      fill="#FD919E"
    />
    <path
      d="M576.6 734.4c-1.2 0-2.6-0.2-3.8-0.6-74-25.6-155-85-240.8-176.8-3.2-3.2-33.2-33.2-55.2-76.2-30.8-60.8-30.4-118.4 1.6-166.2 3-4.6 8.8-6.4 14-4.4l55.2 21.6 86.6-35c3.2-1.4 7-1 10.2 0.6 39.8 22.2 73 36.8 109.2 4.2l7.8 8.8-7.8-8.8c27.8-25 61.2-43.2 96.4-52.4 37.2-9.8 73.8-9 106 2.2 42.4 14.8 96.8 54.6 114 159 17 103.2-21.8 246.8-116 308.2-3.2 2.2-7.4 2.4-10.8 1l-62.4-25.8-99.8 40c-1.4 0.2-3 0.6-4.4 0.6zM293 335.4c-22.2 39-20.8 84.2 4.4 134 20.8 41.4 50.8 70.4 51 70.8l0.4 0.4c81.6 87.4 158 144.4 227.4 169.4l100.2-40.2c2.8-1.2 6-1.2 8.8 0l61 25.2c37-26.2 67.2-70 85.6-124 17.4-51.8 23-109 15-157.2-12.2-73.6-46.2-122.2-98.6-140.6-55.4-19.4-127.2-1-179 45.6-49.2 44.2-97.8 20.4-131.6 1.6L352 355c-2.8 1.2-5.8 1.2-8.6 0l-50.4-19.6z"
      fill="#4C4372"
    />
    <path
      d="M513.8 759.4c-2 0-3.8-0.4-5.6-1.4-72.2-39-149.6-102.4-229.8-188.4-3.4-3.4-38.4-38-65.2-88.6-37.4-70.4-40.6-137.8-9.2-194.6 2.8-5.2 9-7.4 14.6-5.2l133.2 52c6 2.4 9 9.2 6.6 15.2-2.4 6-9.2 9-15.2 6.6L220 306.8c-55.2 118.4 73.6 244.8 75 246l0.4 0.4c76.8 82.2 150.4 143.2 219.2 181.4l161.8-64.8c2.8-1.2 6-1.2 8.8 0l131.6 54.4C903.8 642 933.6 494 917.2 394c-5.4-32.8-14.4-62-26.8-86.6L676 220.8c-6-2.4-9-9.2-6.4-15.2 2.4-6 9.2-9 15.2-6.4l218.4 88.2c2.6 1 4.8 3 6 5.4 14.6 27.8 25 60.6 31 97.6 9.6 58.4 4.6 125.4-13.8 189.2-20.4 69.8-54.6 127.6-99.2 167.4-3.4 3-8.2 3.8-12.2 2l-134.2-55.4-162.4 65.2c-1.6 0.4-3 0.6-4.6 0.6z"
      fill="#4C4372"
    />
    <path
      d="M455.8 782.8c-2.2 0-4.4-0.6-6.4-1.8-72.4-46.8-149-113.4-227.6-197.6-3.8-3.6-43.8-43.2-75.6-101.6-44.2-80.8-51-158.4-19.8-224.8 2.6-5.6 9.2-8.2 14.8-6l206.2 80.4L676 199c2.8-1.2 6-1.2 8.8 0l296.8 119.8c4.4 1.8 7.4 6 7.4 10.8v293c0 1.4-0.2 2.6-0.6 4-21 58.6-51.4 110.2-87.8 149.2-3.4 3.6-8.6 4.6-13 2.8l-206.8-85.4-220.6 88.4c-1.4 0.8-3 1.2-4.4 1.2zM143.4 277c-56.4 141.2 93.4 288.2 95 289.6l0.4 0.4c75.6 81 149 145 218.4 190.8L676.4 670c2.8-1.2 6-1.2 8.8 0l203.6 84c31.6-35.6 58-81.4 76.8-133.2V337.8l-285.2-115.2L352 355c-2.8 1.2-5.8 1.2-8.6 0l-200-78z m834 345.8z"
      fill="#4C4372"
    />
    <path
      d="M345.4 827c-1.4 0-3-0.2-4.4-0.8L42 706c-4.4-1.8-7.4-6-7.4-10.8V226.6c0-3.8 2-7.4 5.2-9.6 3.2-2.2 7.2-2.6 10.8-1.2l296.8 115.8L676 199c2.8-1.2 6-1.2 8.8 0l296.8 119.8c4.4 1.8 7.4 6 7.4 10.8v473.4c0 4-2 7.6-5.2 9.8a11.36 11.36 0 0 1-11 1l-292.2-120.6-331 132.6c-1.2 0.8-2.6 1.2-4.2 1.2zM58.2 687.2l287.2 115.4L676.4 670c2.8-1.2 6-1.2 8.8 0l280.4 115.6v-448l-285.2-115.2L352 355c-2.8 1.2-5.8 1.2-8.6 0L58.2 243.8v443.4z"
      fill="#4C4372"
    />
    <path
      d="M168.2 755.8c-6.4 0-11.6-5.2-11.8-11.6-1-160.4-119.4-406.8-120.6-409.2-2.8-5.8-0.4-12.8 5.4-15.6 5.8-2.8 12.8-0.4 15.6 5.4 0.4 0.6 30.8 63.8 61 146.4C158.6 582.4 179.4 674.4 180 744c0 6.4-5.2 11.8-11.8 11.8zM389.4 809.4c-3 0-5.8-1-8.2-3.2L234 665c-3.6-3.4-7.2-1.8-8.6-1-1.4 0.8-4.6 3.2-3.4 8l24.4 96c1.6 6.2-2.2 12.6-8.4 14.2-6.2 1.6-12.6-2.2-14.2-8.4l-24.4-96c-3.4-13.4 2.4-27 14.2-34 12-7 26.6-5.2 36.6 4.4l147.2 141c4.6 4.4 4.8 12 0.4 16.6-2.2 2.4-5.4 3.6-8.4 3.6zM97.6 727.4h-0.8c-6.4-0.4-11.4-6-11-12.4 6.2-91.8-49.4-213.8-50-215-2.8-5.8-0.2-12.8 5.8-15.6 5.8-2.8 12.8-0.2 15.6 5.8 2.4 5.2 58.8 128.6 52.2 226.4-0.6 6-5.6 10.8-11.8 10.8zM584 561.4c-19.6 0-46-10.6-79-46-13-12.8-31.8-43.2-10.8-69.6 16.6-20.8 35.4-10.2 45.6-4.4 10.8 6.2 12.6 6 15.8 3 15.8-14.2 37.2-19.4 54.8-13.2 9.8 3.4 26.8 13.6 31.8 43 4.8 28.6-7.6 68-35.6 82a51.6 51.6 0 0 1-22.6 5.2z m-67-104.4c-2 0-3 1.2-4.6 3.4-13.2 16.6 8.8 38 9 38.2l0.4 0.4c30 32 56.4 44.8 74.2 36 17.6-8.6 26.2-37 22.8-57-1.6-9.2-5.6-21-16.4-24.8-9.4-3.4-22.2 0-31.4 8.4-16.6 15-33.2 5.6-43.2 0-5.6-3.2-8.6-4.6-10.8-4.6z"
      fill="#4C4372"
    />
    <path
      d="M606.4 622.6c-38.4 0-90.8-21.4-158.2-93.6-24.6-24-60.8-81.6-22-130 28.2-35.4 58-18.4 79.8-6.2 21.2 12 34 18.2 49 4.8 29.8-27 70.4-36.8 103.2-25.4 31 10.8 51.8 39.6 58.6 81 9.2 56.2-14.2 133.2-68.4 159.8-11.4 5.6-25.4 9.6-42 9.6z m-141.8-221.4c-7 0-13 3.6-20.2 12.4-14.4 18-15.8 39.6-4.2 64.2 9.6 20 24.2 34.4 24.4 34.6l0.4 0.4c37.6 40.2 72.6 66.8 104.4 78.8 25.8 9.8 48.8 10 68.6 0.2 34.8-17 65.4-75.4 55.6-135-5.4-33-20.4-54.6-43.4-62.8-24.6-8.6-56.6-0.4-79.8 20.6-28.2 25.4-56 9.6-76.2-1.8-12.6-6.8-21.6-11.6-29.6-11.6z"
      fill="#4C4372"
    />
  </SvgIcon>
);

export default LocationsPageIcon;
