import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import React, { useCallback, useLayoutEffect, useState } from "react";
import {
  createApiClientRequestInterceptor,
  createApiClientResponseInterceptor,
  setApiAuthorizationHeader,
} from "../api/apiClient";
import sessionApi from "../api/sessionApi";
import usersApi from "../api/usersApi";
import Loader from "../components/Loader";
import { setAccessTokenToCookie } from "../helpers/cookies";
import { useAppContext } from "../store";
import { setIsAuth, setUser } from "../store/actions";

interface Props {
  children: React.ReactNode;
}

const UserDataWrapper: React.FC<Props> = (props: Props) => {
  const { children } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch } = useAppContext();

  const [isLoading, setIsLoading] = useState(true);

  const getCurrentUser = useCallback(async () => {
    try {
      const token = await sessionApi.refreshToken();
      if (token.accessToken) {
        setApiAuthorizationHeader(token.accessToken);
        setAccessTokenToCookie(token.accessToken);
        createApiClientRequestInterceptor(() => dispatch(setIsAuth(false)));
        createApiClientResponseInterceptor(() => dispatch(setIsAuth(false)));

        const currentUser = await usersApi.getCurrentUser();

        dispatch(setUser(currentUser));
        dispatch(setIsAuth(true));
      } else {
        dispatch(setIsAuth(false));
      }
    } catch (e) {
      const error = e as AxiosError;
      const responseData = error.response?.data as Error;

      if (error?.response?.status !== 404) {
        enqueueSnackbar(responseData.message || error?.message, {
          variant: "error",
        });
      }
    }
  }, [dispatch, enqueueSnackbar]);

  useLayoutEffect(() => {
    getCurrentUser().then(() => setIsLoading(false));
  }, [getCurrentUser]);

  // eslint-disable-next-line
  return isLoading ? <Loader inline={false} height="100vh" /> : <>{children}</>;
};

export default UserDataWrapper;
