const appLinks = {
  index: {
    name: "Home",
    link: "/",
  },
  signIn: {
    name: "Sign-in",
    link: "/sing-in",
  },
  forgotPassword: {
    name: "Forgot password",
    link: "/password-forgot",
  },
  resetPassword: {
    name: "Reset password",
    link: "/password-reset/:resetToken",
  },
  devices: {
    name: "Devices",
    link: "/devices",
  },
  devicesUpdate: {
    name: "Update Device",
    link: ":id",
    asLink: (id: string): string => `${id}`,
  },
  locationsCreate: {
    name: "Create Location",
    link: "create",
  },
  locationsUpdate: {
    name: "Update Location",
    link: ":id",
    asLink: (id: string): string => id,
  },
  devicesCreate: {
    name: "Create Device",
    link: "create",
  },
  users: {
    name: "Staff",
    link: "/users",
  },
  usersCreate: {
    name: "Create User",
    link: "/users-create",
  },
  settings: {
    name: "Settings",
    link: "/settings",
  },
  settingLocations: {
    name: "Locations",
    link: "locations",
  },
  settingsDeviceTypes: {
    name: "Device Types",
    link: "types",
  },
  profile: {
    name: "Profile",
    link: "/profile",
  },
  calendar: {
    name: "Calendar",
    link: "/calendar",
  },
};

const menuPrimaryLinks = [
  appLinks.devices,
  appLinks.users,
  appLinks.settings,
  appLinks.calendar,
];

export { appLinks, menuPrimaryLinks };
