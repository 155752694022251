const ENDPOINTS = {
  SESSION: "/sessions",
  USER: "/users",
  LOCATIONS: "/locations",
  DEVICES: "/devices",
  DEVICES_TYPES: "/devices/types",
  RESET_PASSWORD: "/reset-password",
  DEVICES_SCHEDULES: "/devices/schedules",
};

export default ENDPOINTS;
