import { SvgIconProps, SvgIcon } from "@mui/material";
import React from "react";

const ArrowBackIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g stroke="currentColor" strokeWidth="1.5">
        <g>
          <g>
            <g>
              <g>
                <path
                  d="M6 9L12 15 18 9"
                  transform="translate(-644 -520) translate(60 258) translate(346 218) translate(0 32) translate(238 12)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default ArrowBackIcon;
